/**
 * @tips - Try to use RGB colors instead of Hexa
 * then, just put 50% in BG_PRIMARY_GRADIENT
 */
export const BG_PRIMARY = 'rgb(16 53 46)';
export const BG_PRIMARY_GRADIENT = 'rgb(16 53 46 / 70%)';
export const BG_SECONDARY = '#D6C9AF';
export const BG_ALTERNATIVE = 'rgb(117 76 51)';
export const BG_ALTERNATIVE_DARK = '#10352e';
// color text
export const TEXT_PRIMARY = '#D6C9AF';
export const TEXT_PRIMARY_LIGHT = '#F5F6E8';
export const TEXT_SECONDARY = '#996211';
export const TEXT_ALTERNATIVE = '#10352e';
export const TEXT_SHADOW = '#10352e';
// music & navigation
export const NAVIGATION_COLOR = '#9a6922';
export const DRAWER_COLOR = 'rgb(16 53 46)';

// DEFAULT BUTTON PROPS
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};